<template>
    <transition name="blur-background">
        <blur v-if="show">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-zipcode-outside-area fixed-modal-wrapper" v-if="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div
                    class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full radius-anchor"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                    <div class="mb-4">
                        <div class="sm:flex sm:items-start">
                            <div class="text-center sm:text-left pt-8 pb-0 px-4  sm:rounded-sm sm:px-10">
                                <h3 class="text-lg leading-6 font-bold text-2xl text-gray-900 mb-3" id="modal-headline">
                                    {{ $t('delivery_method.only_pickup') }}
                                </h3>
                                <div class="mt-2">
                                    <p>{{ $t('delivery_method.no_delivery') }}</p>
                                    <p>{{ $t('delivery_method.pickup_available') }}</p>

                                    <p>{{ $t('delivery_method.edit_zip_code') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span v-if="menu_active" class="mt-3 flex w-full rounded-sm  sm:mt-0 sm:w-auto">
                        <router-link :to="{ name: 'menu', params: {location_slug: location_slug}}" type="button"
                                     class="inline-flex btn btn-close justify-center w-full rounded-sm  px-4 py-3 text-base leading-6 font-medium transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                              {{ $t('delivery_method.button.to_menu_card') }}
                        </router-link>
                    </span>
                    <button type="button" @click="close()"
                            class="btn btn-confirm w-full"
                    >
                          {{ $t('delivery_method.button.change_location') }}
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import * as _applicationState from "../lib/application-state";
import Blur from "./Blur";

export default {
    components: {
      Blur,
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        open () {
            this.show = true;
        },
        close () {
            this.show = false;
            // this.$emit('closed', null);
        },
    },
    computed: {
        location_slug () {
            return this.$route.params.location_slug;
        },

        menu_active () {
            return _applicationState.canAccessFunction('menu');
        },
    },
};
</script>
