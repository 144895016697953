<template>
    <div class="radius-anchor max-w-xl mx-auto ccss--deliverymethod">
        <transition-group tag="div" name="pages">
            <div v-if="!delivery_method">
                <div class>
                    <h2 class="text-2xl leading-9 tracking-tight font-extrabold sm:text-4xl sm:leading-10">
                        {{ $t('delivery_method.title') }} {{ location.name }}</h2>
                    <h3 class="my-3 text-lg text-lg font-bold">{{ $t('delivery_method.subtitle') }}</h3>

                    <div class="method-tiles">
                        <div class="method-outer">
                            <div @click="setDeliveryMethod('takeaway')" class="method radius-anchor takeaway">
                                <span class="img-wrapper">
                                </span>
                                <div>{{ $t('delivery_method.button.takeaway') }}</div>
                            </div>
                        </div>
                        <div class="method-outer">
                            <div @click="setDeliveryMethod('delivery')" class="method radius-anchor delivery">
                                    <span class="img-wrapper">
                                    </span>
                                <div>{{ $t('delivery_method.button.delivery') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="delivery_method == 'delivery' && location.delivery.type == 'zipcode'" class="max-w-xl mx-auto">
                <a v-if="canAccessTakeaway === true" @click="setDeliveryMethod()"
                   class="cursor-pointer flex mb-5 justify-start"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                              fill="currentColor"
                        />
                    </svg>
                    <div class="ml-3">{{ $t('delivery_method.button.back') }}</div>
                </a>

                <Form @submit="submitZipcode" :validation-schema="validationRulesZipcode" v-slot="{ errors }">
                    <div class="mb-3 ">
                        <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                            <h3 class="ml-2 mt-2 text-lg leading-6">
                                {{ $t('delivery_method.enter.zipcodehousenumber') }}
                            </h3>
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-12 gap-4 mb-3">
                        <div class="mb-2 sm:mb-0 sm:col-span-7">
                            <Field type="text" name="zipcode"
                                   :placeholder="$t('fields.zipcode') + ': ' + $t('fields.zipcode.placeholder')"
                                   class="py-3 px-2 block w-full radius-anchor"
                                   :class="{'border-red-500 text-red-500': errors.zipcode}"
                            />
                        </div>
                        <div class="mb-2 sm:mb-0 sm:col-span-5">
                            <Field type="text" name="housenumber"
                                   :placeholder="$t('fields.housenumber') + ': ' + $t('fields.housenumber.placeholder')"
                                   class="py-3 px-2 block w-full radius-anchor"
                                   :class="{'border-red-500 text-red-500': errors.housenumber}"
                            />
                        </div>
                        <div class="sm:col-span-12">
                            <button type="submit"
                                    class="w-full inline-flex justify-center items-center px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                            >
                                {{ $t('delivery_method.button.order') }}
                                <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" viewBox="0 0 24 24"
                                >
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"
                                    ></circle>
                                    <path class="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                <svg v-else class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                          d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                                          clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>

            <!-- Delivery by table/resource -->
            <div v-if="delivery_method == 'delivery' && location.delivery.type == 'tables'" class="max-w-xl mx-auto">
                <a v-if="canAccessTakeaway === true" @click="setDeliveryMethod()"
                   class="cursor-pointer flex mb-5 justify-start"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                              fill="currentColor"
                        />
                    </svg>
                    <div class="ml-3">{{ $t('delivery_method.button.back') }}</div>
                </a>

                <Form @submit="submitTable" :validation-schema="validationRulesTable" v-slot="{ errors }">
                    <div class="mb-3">
                        <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                            <h3 class="ml-2 mt-2 text-lg leading-6">
                                {{ $t('delivery_method.button.location') }}
                            </h3>
                        </div>
                    </div>
                    <div class="">
                        <div class="mb-3 mb-0 sm:col-span-7">
                            <Field as="select" v-model="table_id" name="table_id"
                                   class="py-1 px-2 radius-anchor h-10 block w-full shadow-sm rounded-sm focus:outline-none focus:ring-transparent"
                                   :class="{'error': errors.table_id}"
                            >
                                <option></option>
                                <option v-for="(table, index) in tables" :key="index" :value="table.id"
                                        :selected="table_id == table.id"
                                >{{ table.attributes.name }}
                                </option>
                            </Field>
                        </div>
                        <div class="sm:col-span-5">
                            <button type="submit"
                                    class="w-full inline-flex justify-center items-center px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                            >
                                {{ $t('delivery_method.button.order') }}
                                <svg class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                          d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                                          clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>

            <div class="flex justify-center my-20" v-if="delivery_method == 'takeaway'">
                <div class="w-1/2">
                    <div class="p-5 mt-5 border shadow-sm">
                        <div>
                            <div class="sm:grid sm:grid-cols-3 gap-4 mb-3">
                                <div class="sm:col-span-2">
                                    <div>Ons adres is</div>
                                    <div class="font-bold">{{ location.streetname }} {{ location.housenumber }}</div>
                                    <div class="font-bold">{{ location.zipcode }} {{ location.city }}</div>
                                </div>
                                <div class="sm:col-span-1">
                                    <router-link :to="{ name: 'menu', params: {location_slug: location_slug}}"
                                                 class="w-full inline-flex justify-center items-center px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                                    >
                                        {{ $t('delivery_method.button.order') }}
                                        <svg class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd"
                                                  d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                                                  clip-rule="evenodd"
                                            />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5 font-bold">

                            {{ $t('delivery_method.button.redirect') }}

                            <router-link :to="{ name: 'menu', params: {location_slug: location_slug}}"
                                         class="underline"
                            >
                                {{ $t('navigation.menu_card"') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>

        <modal-zipcode-outsidearea ref="zipcode" />
    </div>
</template>

<script>
import axios from 'axios';
import {
    Field,
    Form,
} from 'vee-validate';
import * as yup from 'yup';

// libs
import * as _address from '../lib/address';
import * as _api from '../lib/api';
import * as _applicationState from '../lib/application-state';
import * as _location from "../lib/location";
import * as _state from '../lib/state';
import * as _transaction from '../lib/transaction';

// modals
import ModalZipcodeOutsidearea from '../components/ModalZipcodeOutsidearea';

export default {
    name: 'Deliverymethod',
    // mixins: [AddressHelper, DeliveryHelper],
    props: ['location_slug'],
    components: {
        Field,
        Form,
        ModalZipcodeOutsidearea,
    },
    data () {
        return {
            validationRulesZipcode: yup.object().shape({
                zipcode: yup.string().matches(/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i).required().min(4).max(255),
                housenumber: yup.string().matches(/^[1-9](\d{0,4})([A-Za-z])?([-\s]{1}[0-9A-Za-z]{1,4})?$/i).required().min(1).max(255),
            }),
            validationRulesTable: yup.object().shape({
                table_id: yup.string().required(),
            }),
            saving: false,
            delivery_method: '',
            tables: [],
            table_id: '',
        };
    },
    async mounted () {
        // todo: move to global location (main.js?)
        axios.defaults.headers.common['Location'] = this.location_slug;

        if (this.canAccessDelivery === true && this.canAccessTakeaway === true) {
            return;
        } else if (this.canAccessDelivery === true) {
            return this.setDeliveryMethod('delivery');
        } else if (this.canAccessTakeaway === true) {
            return this.$router.push({ name: 'takeaway', params: { location_slug: this.location.slug } });
        } else {
            location.href = '/403';
        }
    },

    methods: {
        async getTables () {
            this.tables = await _api.get('/locations/' + this.location.slug + '/tables');
        },

        async submitZipcode (data) {

            if (this.saving === true) {
                return;
            }

            this.saving = true;

            if (!await _address.isZipcodeAvailable(data.zipcode)) {
                this.$refs.zipcode.open();

                this.saving = false;
                return;
            }

            // get the delivery address
            let address = await _address.retrieve(data.zipcode, data.housenumber);

            _transaction.setDeliveryAddress({
                zipcode: address.zipcode,
                housenumber: address.housenumber,
                streetname: address.streetname,
                city: address.city,
            });

            this.$router.push('/' + this.location_slug + '/bezorgen');
        },

        async submitTable (values) {
            let table = await this.tables.find(table => table.id === values.table_id);

            // clear delivery_address
            _transaction.setDeliveryAddress(false);
            _transaction.setTable(table);

            this.$router.push('/' + this.location_slug + '/bezorgen');
        },

        async setDeliveryMethod (method) {

            if (!method && this.canAccessDelivery === true && this.canAccessTakeaway === false) {
                return this.setDeliveryMethod('delivery');
            }

            // clear the table
            _transaction.setTable(false);

            // set the method
            _transaction.setMethod(method);

            if (!this.location.slug) {
                await _location.init(this.location_slug);
            }

            _transaction.setMethodDelivery(this.location.delivery.type);

            if (method == 'takeaway') {
                this.$router.push('/' + this.location_slug + '/afhalen');

                return;
            }

            if (method == 'delivery') {

                this.delivery_method = method;

                if (this.location.delivery.type == 'tables') {
                    await this.getTables();
                }

                return;
            }

            // reset the method
            this.delivery_method = '';
        },
    },
    computed: {
        location () {
            return _state.get('location/getLocation');
        },

        canAccessDelivery () {
            return _applicationState.canAccessFunction('delivery');
        },

        canAccessTakeaway () {
            return _applicationState.canAccessFunction('takeaway');
        },
    },
};
</script>
